.sender a,
.receiver a,
.notfound a {
    width: 100px;
    height: 50px;
    color: #fff;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.sender a svg,
.receiver a svg,
.notfound a svg {
    font-size: 1em;
    color: #fff;
}