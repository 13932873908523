.home .card a {
    width: 300px;
    text-decoration: none;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home .lang {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1em auto;
}

.home .lang button {
    border: none;
    background: none;
    font-size: 1.5em;
    color: #fff;
    font-weight: 700;
}

.home > p {
    width: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.home .card {
    width: 100%;
    border: none;
    border-radius: 0;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .card a svg {
    font-size: 10em;
    color: #fff;
}

.home .card a p {
    width: 100%;
    height: 100px;
    font-size: 2em;
    padding: 0.5em;
    border-radius: 0.5em;
    border: none;
    background-color: #ff8181;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .card a:hover > p,
.home .card a:active > p  {
    color: #ff8181;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 300ms ease-in-out;
}

.home section p {
    font-size: 1.2em;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.home section a {
    color: #b30a0a;
}

@media (max-width: 768px) {
    .home > p {
        width: 90%;
    }

    .home .card a {
        width: 100%;
    }

    .home .card a svg {
        font-size: 5em;
    }

    .home .card a p {
        font-size: 1.1em;
    }

    .home section p {
        font-size: 1em;
    }
}