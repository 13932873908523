body {
    background-color: #ff3737;
}

.home,
.sender,
.receiver,
.notfound {
    width: 100%;
    padding: 1em;
    display: grid;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
}

  
h1 {
    color: #fff;
    font-size: 5em;
    text-align: center;
}

h2 {
    color: #fff;
    margin: 1em auto;
    text-align: center;
}

h3 {
    color: #fff;
    margin: 1em auto;
    text-align: center;
    font-size: 1em;
}

p {
    font-size: 1.5em;
    color: #fff;
    margin: 2em auto;
    text-align: center;
}

hr {
    color: #fff;
    width: 300px;
    margin: 1em auto;
}

input,
button {
    outline: none;
    box-shadow: none!important;
    border: none;
}

.notfound {
    width: 100%;
    height: 100vh;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notfound h1 {
    font-size: 11em;
    padding: 0;
    margin: 0;
}

.notfound h2 {
    font-size: 6em;
    padding: 0;
    margin: 0;
}

.sender .card,
.receiver .card {
    border: none;
    padding: 1em;
    background: none;
    transition: all 300ms ease-in-out;
}

.progreso-container {
    margin-top: 20px;
}

.progreso-barra {
    border: 1px solid #ccc;
    width: 100%;
    height: 20px;
    border-radius: 5px;
    overflow: hidden;
}

.progreso-barra div {
    background-color: #337ab7; /* barra de progreso azul */
    height: 100%;
    transition: width 0.5s ease-in-out;
}

.confirmacion-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.confirmacion-container h2 {
    color: #337ab7; /* título azul */
    font-size: 20px;
    margin-bottom: 10px;
}
  
.confirmacion-container button {
    background-color: #337ab7; /* botón azul */
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
}

.confirmacion-container button:hover {
    background-color: #23527c; /* hover azul más oscuro */
}

.file-receiver-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

label {
    font-size: 1.7em;
    color: #fff;
}
  
input[type="text"] {
    width: 100%;
    background: none;
    color: #fff;
    font-size: 1.2em;
    border: none;
    border-bottom: 5px solid #fff;
    border-radius: 0;
    margin-bottom: 3em;
}

input[type="file"] {
    padding: 1em;
    color: #fff;
    font-size: 1.2em;
    border: 2px dashed #fff;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 3em;
}

button[type="submit"] {
    background-color: #337ab7;
    color: #fff;
    border: none;
    padding: 0.5em 2em;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 0;
}

button[type="submit"]:hover {
    background-color: #23527c;
}

.check {
    background-color: #fff;
    color: #000;
    border: none;
    padding: 0.5em 2em;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 0;
}

.download {
    background-color: #337ab7;
    color: #fff;
    border: none;
    padding: 0.5em 2em;
    font-size: 1em;
    cursor: pointer;
    border-radius: 0;
}

.download:hover {
    background-color: #23527c;
}

@media (max-width: 768px) {
    p {
        font-size: 1em;
    }
}