.brand {
    font-family: "Playwrite FR Moderne", cursive;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 4em;
    margin: 0.5em 0;
}

@media (max-width: 833px) {
    .brand {
        font-size: 3em;
    }

    .brand img {
        width: 70px;
    }
}